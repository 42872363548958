import moment from 'moment';
// import ZdhcServiceV2 from '../services/Zdhc/ZdhcServiceV2.js';
import ZdhcServiceV5 from '../services/Zdhc/ZdhcServiceV5.js';
import ProductService from '../services/ProductService';
import ZdhcProductService from '../services/ZdhcProductService';
import IncheckReferenceService from '../services/IncheckReferenceService.js';
import GoogleService from '../services/GoogleService.js';
import { reactive, ref } from 'vue';
const useMatchAlgorithm = () => 
{
    /**
     * 
     * Data
     * 
     */
    const counter = ref();
    const product = reactive({});
    const { /* searchExactMatch,  */storeExactMatch } = ProductService();
    const { translate } = GoogleService();
    const { storeZdhcProduct, deleteZdhcProduct } = ZdhcProductService();
    //const { getProductByGuid } = ZdhcServiceV2();
    const { searchZdhcProductByFormulator, searchZdhcProductByWord, searchZdhcProductByGuid } = ZdhcServiceV5();
    const { updatePreviousResults } = IncheckReferenceService();
    /**
     * 
     * Methods
     * 
     */
    const assignProduct = async(p) => 
    {
        Object.assign(product, p);
    }
    const checkExistingMatch = async (p) => 
    {
        Object.assign(product, p);
        const exactMatchRes = await checkExactMatch();
        return exactMatchRes;
        /* let zdhcProduct = null;
        let hasInventory = checkForInventory(product);
        if(!hasInventory)
        {
            if(product.incheck_reference.suggestion_id) zdhcProduct = product.incheck_reference.suggestion.zdhc_product;
            else if(product.incheck_reference.zdhc_product_id) zdhcProduct = product.incheck_reference.match;
            if(zdhcProduct?.guid)
            {
                const res = await getProductByGuid(zdhcProduct.guid);
                if(res.data?.result?.errorMessage && res.data?.result?.errorMessage === 'No results found.')
                {
                    // Delete Zdhc Product
                    const deleteRes = await deleteZdhcProduct(zdhcProduct.guid);
                    if(deleteRes === 200)
                    {
                        product.incheck_reference.suggestion_id = null;
                        product.incheck_reference.suggestion = null;
                        product.incheck_reference.zdhc_product_id = null;
                        product.incheck_reference.match = null;
                        const exactMatchRes = await checkExactMatch();
                        return exactMatchRes;
                    }
                }
                else
                {
                    // Store Zdhc Product
                    let zp = res?.data?.data[0];
                    translate(zp.productName)
                    .then( async (translation_res) => 
                    {
                        let translated_name = translation_res.data.translations[0].translatedText;
                        let detected_lang = translation_res.data.translations[0].detectedSourceLanguage;
                        if(zp.productName.toLowerCase() === translated_name.toLowerCase())
                        {
                            zp.translated_names = { [detected_lang] :  zp.productName.toLowerCase() };
                        }
                        else 
                        {
                            zp.translated_names = 
                            { 
                                [detected_lang] :  zp.productName.toLowerCase(),
                                'en' : translated_name.toLowerCase(),
                            };
                        }
                        const storeRes =  await storeZdhcProduct(zp);
                        if(storeRes) 
                        {
                            const exactMatchRes = await checkExactMatch();
                            return exactMatchRes;
                        }
                    });
                }
            }
        } */
    };
    const checkExactMatch = async () => 
    {
        let hasInventory = checkForInventory(product);
        if(!hasInventory)
        {
            if(product.formulator || product.formulator_name)
            {
                let formulator_name = product.formulator ? product.formulator.name : product.formulator_name;
                const res = await searchZdhcProductByFormulator(product.name, formulator_name.toLowerCase());
                if(res) 
                {
                    let availableMatches = [];
                    if(res.length)
                    {
                        availableMatches = res.filter(el =>
                        {
                            return el.fullProduct.productName.toLowerCase() == product.name.toLowerCase() && el.fullProduct.formulatorName.toLowerCase() == formulator_name.toLowerCase();
                        });
                        if(availableMatches.length == 1)
                        {
                            let true_match = availableMatches[0];
                            true_match.reference_date = product.incheck_reference.reference_date;
                            const storeRes = await storeExactMatch(product.id, true_match);
                            if(storeRes)
                            {
                                product.incheck_references = storeRes.data.p.incheck_references;
                                product.incheck_reference = storeRes.data.p.incheck_reference;
                                product.formulator = storeRes.data.p.formulator;
                            }
                            return {
                                status: 'ok',
                                product: product,
                                productStatus: 'success'
                            };
                            
                        }
                    }
                }
            }
            if(product.incheck_reference.no_match_id)
            {
                /* If product has NoMatch status, check for new ZDHC products */
                return checkNoMatchNewAssociables();
            }
        
            return {
                status: 'ko',
            };
        }
        else 
        {
            if(product.incheck_reference.match)
            {
                return {
                    status: 'ok',
                    productStatus: 'success'
                }
            }
            else if(product.incheck_reference.suggestion_id) 
            {
                return {
                    status: 'ok',
                    productStatus: 'suggestion'
                }
            }
            else if(product.incheck_reference.no_match_id) 
            {
                return {
                    status: 'ok',
                    productStatus: 'manualNoMatch'
                }
            }
        }
    };
    const checkForInventory = () => 
    {
        let hasInventory = false;
        if(Object.keys(product).length)
        {
            let incheck_date = moment(product.incheck_reference.reference_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
            if(product.inventories.length)
            {
                hasInventory = product.inventories.some( i => 
                {
                    let inventory_date = moment(i.reference_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
                    return moment(inventory_date).isSame(moment(incheck_date));
                });
            }
        }
        return hasInventory;
    };
    // Search for matches - first phase
    const searchMatchesFirstPhase = async () => 
    {
        let matches = [];
        let matchId = '';
        if(product.incheck_reference?.zdhc_product_id) matchId = product.incheck_reference.zdhc_product_id
        else if(product.incheck_reference?.suggestion_id)  matchId = product.incheck_reference.suggestion.zdhc_product_id;

        let zdchRes = await searchZdhcProductByWord(product.name);
        if(zdchRes)
        { 
            zdchRes = zdchRes.filter(p => 
            {
                return p.id === matchId || p.formulator_name !== 'No match Formulator';
            });
            zdchRes.forEach( p => {if(p.id === matchId || p.formulator_name !== 'No match Formulator') matches.push(p);});
        }
        return matches;
    };
    const searchMatchesSecondPhase = async () => 
    {
        let matches = [];
        let matchId = '';
        if(product.incheck_reference?.zdhc_product_id) matchId = product.incheck_reference.zdhc_product_id
        else if(product.incheck_reference?.suggestion_id)  matchId = product.incheck_reference.suggestion.zdhc_product_id;
        // 1 - Translate the product name (EN)
        let googleRes = await translate(product.name);
        if(googleRes)
        {
            let translated_name = googleRes.data.translations[0].translatedText;
            // 2 - Search match into zdhc records 
            let zdhcRes = await searchZdhcProductByWord(translated_name);
            if(zdhcRes)
            {
                zdhcRes = zdhcRes.filter(p => 
                {
                    return p.id === matchId || p.formulator_name !== 'No match Formulator';
                });
                zdhcRes.forEach( p => {if(p.id === matchId || p.formulator_name !== 'No match Formulator') matches.push(p);});
            }
        }
        return matches;
    };
    const searchMatchesThirdPhase = async () => 
    {
        let matches = [];
        let matchId = '';
        let splittedName = product.name.split(' ');
        if(product.incheck_reference?.zdhc_product_id) matchId = product.incheck_reference.zdhc_product_id
        else if(product.incheck_reference?.suggestion_id)  matchId = product.incheck_reference.suggestion.zdhc_product_id;

        splittedName = splittedName.filter( s => s.length > 2);

        const totalLength =  splittedName.length;
        let actualResLength = 0;
        //let totalSuggestionsRes = [];
        if(totalLength)
        {
            for (const splitted_word of splittedName) 
            {
                // Get matches for 'word'
                let zdhcRes = await searchZdhcProductByWord(splitted_word);
                // ZDHC matches
                if(zdhcRes) 
                {
                    actualResLength +=1;
                    if(zdhcRes.length) 
                    {
                        zdhcRes = zdhcRes.filter(p => 
                        {
                            return p.id === matchId || p.formulator_name !== 'No match Formulator';
                        });
                        zdhcRes.forEach( p => {if(p.id === matchId || p.formulator_name !== 'No match Formulator') matches.push(p);});
                    }
                }
                if(actualResLength === totalLength) return matches;
              }
        }
        else return matches;
    };
    const checkForExistingZdhcProduct = async (products) => 
    {
        counter.value = 0;
        try 
        {
            for(let i = 0; i < products.length; i++)
            {
                counter.value += 1;
                let p = products[i];
                let incheck_reference = p.incheck_references[0];
                let zdhcGuid = incheck_reference.no_match_id 
                                ? incheck_reference.no_match_id
                                : (incheck_reference.zdhc_product_id 
                                    ? incheck_reference.match?.guid 
                                    : (incheck_reference.suggestion_id 
                                        ? incheck_reference.suggestion?.zdhc_product?.guid
                                        : ''));

                if(zdhcGuid)
                {   
                    //const v2res = await getProductByGuid(zdhcGuid, source);
                    const res = await searchZdhcProductByGuid(zdhcGuid);
                    if(res.data?.result?.errorMessage && res.data?.result?.errorMessage === 'No results found.')
                    {
                        // Delete Zdhc Product
                        deleteZdhcProduct(zdhcGuid);
                    }
                    else
                    {
                        // Store Zdhc Product
                        let zp = res?.data?.data[0];
                        translate(zp.productName)
                        .then( async (translation_res) => 
                        {
                            let translated_name = translation_res.data.translations[0].translatedText;
                            let detected_lang = translation_res.data.translations[0].detectedSourceLanguage;
                            if(zp.productName.toLowerCase() === translated_name.toLowerCase())
                            {
                                zp.translated_names = { [detected_lang] :  zp.productName.toLowerCase() };
                            }
                            else 
                            {
                                zp.translated_names = 
                                { 
                                    [detected_lang] :  zp.productName.toLowerCase(),
                                    'en' : translated_name.toLowerCase(),
                                };
                            }
                            storeZdhcProduct(zp);
                        });
                    }
                }
            }
            if(counter.value == products.length) return {status: 'success'};
            else return {status: 'error'};
        }
        catch(e)
        {
            console.log(e)
            return {status: 'error'};
        }
    };
    const checkNoMatchNewAssociables =  async () => 
    {
        let guids = [];
        let res;
        res = await searchMatchesFirstPhase();
        if(res.length)
        {
            res.forEach(p => {if(!guids.includes(p.guid)) guids.push(p.guid)});
        }
        else 
        {
            res = await searchMatchesSecondPhase();
            if(res.length)
            {
                res.forEach(p => {if(!guids.includes(p.guid)) guids.push(p.guid)});
            }
            else 
            {
                res = await searchMatchesThirdPhase();
                if(res.length)
                {
                    res.forEach(p => {if(!guids.includes(p.guid)) guids.push(p.guid)});
                }
            }
        }

        if(guids)
        {
            const res = await updatePreviousResults(product.incheck_reference.id, {new_results: [...guids]});
            if('incheck_reference' in res)
            {
                product.incheck_reference = res.incheck_reference;
                return {
                    status: 'ok',
                    product: product,
                };
            }
        }
        return {
            status: 'ko',
        };
    }

    return {
        /* Data */
        counter,
        /* Methods */
        assignProduct,
        checkExistingMatch,
        checkExactMatch,
        searchMatchesFirstPhase,
        searchMatchesSecondPhase,
        searchMatchesThirdPhase,
        checkForExistingZdhcProduct
    };
};

export default useMatchAlgorithm;